import { useState } from "react";
import { loadDataFromElement } from "../utils/hydrate-data";
import { FeatureFlagsContext, type FeatureFlags } from "./context";

const _features = loadDataFromElement<FeatureFlags | undefined>(
  "feature-flags",
  undefined,
);

export const FeatureFlagsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [features, setFeatures] = useState<Partial<FeatureFlags>>(
    _features ?? {},
  );

  return (
    <FeatureFlagsContext.Provider value={{ features, setFeatures }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
