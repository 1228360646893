import {
  Badge,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { useUnmanagedRegionNodesQuery } from "../hooks/useUnmanagedRegionNodesQuery";

export function UnmanagedNodesOverview({ region }: { region: string }) {
  const nodesQuery = useUnmanagedRegionNodesQuery(region, {
    refetchInterval: 5000,
  });

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Id</Th>
            <Th>IP Address</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {(nodesQuery?.data ?? [])?.map((unmanagedNode) => {
            return (
              <Tr key={unmanagedNode.id}>
                <Td>
                  <Tooltip label={unmanagedNode.id}>
                    <Text
                      maxWidth={20}
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {unmanagedNode.id}
                    </Text>
                  </Tooltip>
                </Td>
                <Td>{unmanagedNode.ipAddress ?? "n/a"}</Td>
                <Td>
                  <Badge
                    variant="subtle"
                    colorScheme={
                      unmanagedNode.isDisabled
                        ? "gray"
                        : unmanagedNode.isInUse
                          ? "blue"
                          : unmanagedNode.isReady
                            ? "green"
                            : "yellow"
                    }
                  >
                    {unmanagedNode.isDisabled
                      ? "Disabled"
                      : unmanagedNode.isInUse
                        ? "In use"
                        : unmanagedNode.isReady
                          ? "Ready"
                          : "Not Ready"}
                  </Badge>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
