import { mixed, number, object, string } from "yup";
import { SessionManagementApi } from "./types";

export const sessionSchema = object({
  id: string().uuid().required(),
  userIdentifier: number().required().positive().integer(),
  appId: string().required(),
  appBuildId: number().required().positive().integer(),
  appTargetPlatform: mixed<SessionManagementApi.TargetPlatform>().defined(),
  deviceIdentifier: string().required(),
  deviceType: mixed<SessionManagementApi.DeviceType>().defined(),
  organizationId: number().required().positive().integer(),
  experienceStartedDateTime: string().defined().nullable(),
  sessionTerminatedDateTime: string().defined().nullable(),
  renderRegion: string().defined().nullable(),
  vmSize: string().defined().nullable(),
  starRating: number().defined().nullable(),
  ratingComment: string().defined().nullable(),
  virtualMachineId: string().defined().nullable(),
});

const durationSchema = string().matches(
  /^([0-9]{2}):([0-9]{2}):([0-9]{2})$/,
  "Duration must be in the format HH:MM:SS",
);

export const cloudXREnryptionPolicyOptions = [
  "Disallowed",
  "OptIn",
  "OptOut",
  "Enforced",
] as const;

export type CloudXREncryptionPolicy =
  (typeof cloudXREnryptionPolicyOptions)[number];

const cloudXREncryptionSchema = mixed<CloudXREncryptionPolicy>()
  .oneOf(cloudXREnryptionPolicyOptions, "Invalid CloudXR encryption policy")
  .defined();

export const cloudRenderingPoliciesSchema = object().shape({
  cloudXREncryption: cloudXREncryptionSchema.optional().default("OptIn"),
  cloudRenderingSettings: object({
    maxSoftSessionRunTime: durationSchema.optional(),
    maxHardSessionRunTime: durationSchema.optional(),
    sessionInactivityTimeoutTime: durationSchema.optional(),
    reuseHoldBackTime: durationSchema.optional(),
  }).optional(),
  unmanagedNodesSettings: object({
    maxSoftSessionRunTime: durationSchema.optional(),
    maxHardSessionRunTime: durationSchema.optional(),
    sessionInactivityTimeoutTime: durationSchema.optional(),
    reuseHoldBackTime: durationSchema.optional(),
  }).optional(),
  vrStandaloneSettings: object({
    maxSoftSessionRunTime: durationSchema.optional(),
    maxHardSessionRunTime: durationSchema.optional(),
    sessionInactivityTimeoutTime: durationSchema.optional(),
  }).optional(),
  desktopRenderedSettings: object({
    maxSoftSessionRunTime: durationSchema.optional(),
    maxHardSessionRunTime: durationSchema.optional(),
    sessionInactivityTimeoutTime: durationSchema.optional(),
  }).optional(),
});
