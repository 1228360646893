import {
  ChakraProvider as BaseChakraProvider,
  CircularProgress,
} from "@chakra-ui/react";
import { QueryClientProvider } from "@tanstack/react-query";
import {
  Navigate,
  Outlet,
  Route,
  RouteMatch,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ChakraProvider } from "./Providers";

import { wrapCreateBrowserRouter } from "@sentry/react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { Providers } from "./Providers";
import {
  ApplicationAccess,
  ApplicationActivity,
  ApplicationBuilds,
  ApplicationCloudRenderingSettings,
  ApplicationConfiguration,
  ApplicationDetails,
  ApplicationInfo,
  ApplicationList,
  ApplicationManagementPage,
} from "./applications";
import { ApplicationCategorization } from "./applications/ApplicationCategorization";
import { ApplicationCategoryDetails } from "./applications/ApplicationCategoryDetails";
import { ApplicationCategoryList } from "./applications/ApplicationCategoryList";
import { ApplicationIntegrations } from "./applications/ApplicationIntegrations";
import { LazyApplicationDisplayName } from "./applications/components/LazyApplicationDisplayName";
import {
  Login,
  Logout,
  RequireAnyControlPanelPermission,
  RequireAuth,
  RequireAuthz,
} from "./auth";
import OAuth2AuthCodeCallback from "./auth/OAuth2AuthCodeCallback";
import { CloudRendering } from "./cloud-rendering";
import {
  BreadcrumbNavLink,
  Layout,
  UserGroupName,
  UserName,
} from "./components";
import { RouterErrorElement } from "./components/ErrorBoundary";
import { ApplicationCategoryName } from "./components/LazyDisplayName";
import { ConfirmDialogProvider } from "./confirm-dialog";
import { Dashboard } from "./dashboard";
import { FeatureFlagsProvider } from "./feature-flags/provider";
import {
  UserGroupAccess,
  UserGroupDetails,
  UserGroupInfo,
  UserGroupList,
  UserGroupSystemAccess,
} from "./groups";
import { AccountSetup } from "./login/AccountSetup";
import { CollectEmailForm } from "./login/CollectEmailForm";
import { LoginForm } from "./login/LoginForm";
import { BaseLayout, LoginLayout } from "./login/LoginLayout";
import { LogoutSuccessful } from "./login/LogoutSuccessful";
import { OrganizationSelectForm } from "./login/OrganizationSelectForm";
import { OrganizationalLoginForm } from "./login/OrganizationalLoginForm";
import { PasswordChangeDone } from "./login/PasswordChangeDone";
import { PasswordChangeForm } from "./login/PasswordChangeForm";
import { PasswordResetComplete } from "./login/PasswordResetComplete";
import { PasswordResetForm } from "./login/PasswordResetForm";
import { RequestPasswordResetDone } from "./login/RequestPasswordResetDone";
import { RequestPasswordResetForm } from "./login/RequestPasswordResetForm";
import { SSOErrorView } from "./login/SSOErrorView";
import { ValidateEmail } from "./login/ValidateEmail";
import { AuthorizationErrorView } from "./oauth2/AuthorizationErrorView";
import { AuthorizationSuccessView } from "./oauth2/AuthorizationSuccessView";
import { AuthorizationView } from "./oauth2/AuthorizationView";
import { OrganizationSettings } from "./organization";
import { AccessDenied } from "./pages/AccessDenied";
import { CatchAllRoute } from "./pages/CatchAllRoute";
import { LandingPage } from "./pages/LandingPage";
import { TermsConsentPage } from "./pages/TermsConsent";
import { queryClient } from "./queryClient";
import { namedRoutes, routerBasename } from "./routes";
import { SessionsList } from "./sessions/SessionsList";
import { baseTheme } from "./theme/theme";
import {
  UserAccess,
  UserActivity,
  UserDetails,
  UserInfo,
  UserList,
  UserManagementPage,
} from "./users";

// wrap the router to catch errors
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Outlet />
        </QueryParamProvider>
      }
    >
      <Route path="" index element={<Navigate to={namedRoutes.landing} />} />
      <Route
        element={
          <QueryClientProvider client={queryClient}>
            <ChakraProvider>
              <Outlet />
            </ChakraProvider>
          </QueryClientProvider>
        }
      >
        <Route path={namedRoutes.oauth.authorize} element={<LoginLayout />}>
          <Route
            path={namedRoutes.oauth.authorizationSuccess}
            element={<AuthorizationSuccessView />}
            handle={{ title: "Authorization Success" }}
          />
          <Route
            path={namedRoutes.oauth.authorizationError}
            element={<AuthorizationErrorView />}
            handle={{ title: "Authorization Error" }}
          />
          <Route index element={<AuthorizationView />} />
        </Route>
        <Route path="auth">
          <Route
            element={
              <BaseLayout maxWidth={"container.lg"}>
                <Outlet />
              </BaseLayout>
            }
          >
            <Route
              path={namedRoutes.organizationalLogin}
              element={<OrganizationalLoginForm />}
            />
            <Route path={namedRoutes.setupAccount} element={<AccountSetup />} />
          </Route>
          <Route element={<LoginLayout />}>
            <Route path="login" element={<LoginForm />} />
            <Route path="sso">
              <Route
                path={namedRoutes.sso.collectEmail}
                element={<CollectEmailForm />}
              />
              <Route
                path={namedRoutes.sso.validateEmail}
                element={<ValidateEmail />}
              />
              <Route path={namedRoutes.sso.error} element={<SSOErrorView />} />
            </Route>
            <Route
              path={namedRoutes.resetPassword.complete}
              element={<PasswordResetComplete />}
            />
            <Route
              path={namedRoutes.resetPassword.requested}
              element={<RequestPasswordResetDone />}
            />
            <Route
              path={namedRoutes.resetPassword.request}
              element={<RequestPasswordResetForm />}
            />
            <Route
              path={namedRoutes.resetPassword.set}
              element={<PasswordResetForm />}
            />
            <Route
              path={namedRoutes.logoutSuccessful}
              element={<LogoutSuccessful />}
            />
          </Route>
          <Route
            element={
              <Providers>
                <LoginLayout />
              </Providers>
            }
          >
            <Route
              path={namedRoutes.privacy.consent}
              element={<TermsConsentPage />}
            />
            <Route
              path={namedRoutes.changePassword.request}
              element={
                <RequireAuth>
                  <Outlet />
                </RequireAuth>
              }
            >
              <Route
                path={namedRoutes.changePassword.done}
                element={<PasswordChangeDone />}
              />
              <Route path="" element={<PasswordChangeForm />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="control-panel">
        <Route
          path={namedRoutes.organization.select}
          element={
            <BaseChakraProvider theme={baseTheme}>
              <QueryClientProvider client={queryClient}>
                <BaseLayout>
                  <OrganizationSelectForm />
                </BaseLayout>
              </QueryClientProvider>
            </BaseChakraProvider>
          }
          errorElement={<RouterErrorElement />}
        />
        <Route
          path=""
          element={
            <Providers>
              <Outlet />
            </Providers>
          }
        >
          <Route path={namedRoutes.login} element={<Login />} />
          <Route
            path={namedRoutes.oauth2Callback}
            element={<OAuth2AuthCodeCallback />}
          />
          <Route
            path={namedRoutes.logout}
            element={
              <RequireAuth>
                <Logout />
              </RequireAuth>
            }
          />
          <Route
            element={
              <RequireAuth>
                <RequireAnyControlPanelPermission>
                  <ConfirmDialogProvider>
                    <Layout />
                  </ConfirmDialogProvider>
                </RequireAnyControlPanelPermission>
              </RequireAuth>
            }
          >
            <Route index element={<LandingPage />} />
            <Route
              path={namedRoutes.dashboard}
              element={
                <RequireAuthz requiredPermissions={["core.view_user"]}>
                  <Dashboard />
                </RequireAuthz>
              }
              handle={{ title: "Dashboard" }}
              errorElement={<RouterErrorElement />}
            />
            <Route
              path={namedRoutes.application.list}
              handle={{
                crumb: () => (
                  <BreadcrumbNavLink to={namedRoutes.application.list}>
                    Applications
                  </BreadcrumbNavLink>
                ),
                title: "Applications",
              }}
              element={
                <RequireAuthz requiredPermissions={["assets.add_application"]}>
                  <ApplicationManagementPage />
                </RequireAuthz>
              }
              errorElement={<RouterErrorElement />}
            >
              <Route
                path={namedRoutes.applicationCategory.list}
                handle={{
                  crumb: () => (
                    <BreadcrumbNavLink
                      to={namedRoutes.applicationCategory.list}
                    >
                      Categories
                    </BreadcrumbNavLink>
                  ),
                  title: "Application Categories",
                }}
                element={
                  <RequireAuthz
                    requiredPermissions={["assets.add_applicationcategory"]}
                  >
                    <Outlet />
                  </RequireAuthz>
                }
                errorElement={<RouterErrorElement />}
              >
                <Route index element={<ApplicationCategoryList />} />
                <Route
                  path={namedRoutes.applicationCategory.overview}
                  element={<ApplicationCategoryDetails />}
                  handle={{
                    crumb: (match: RouteMatch) => (
                      <BreadcrumbNavLink to={match.pathname}>
                        {match.params.applicationCategoryId && (
                          <ApplicationCategoryName
                            id={Number(match.params.applicationCategoryId)}
                          />
                        )}
                      </BreadcrumbNavLink>
                    ),
                  }}
                />
              </Route>
              <Route index element={<ApplicationList />} />
              <Route
                path={namedRoutes.application.overview}
                element={<ApplicationDetails />}
                handle={{
                  crumb: (match: RouteMatch) => (
                    <BreadcrumbNavLink to={match.pathname}>
                      {match.params.applicationId && (
                        <LazyApplicationDisplayName
                          applicationId={match.params.applicationId}
                        />
                      )}
                    </BreadcrumbNavLink>
                  ),
                }}
                errorElement={<RouterErrorElement />}
              >
                <Route index element={<ApplicationInfo />} />
                <Route
                  path={namedRoutes.application.activity}
                  element={<ApplicationActivity />}
                />
                <Route
                  path={namedRoutes.application.configuration}
                  element={<ApplicationConfiguration />}
                />
                <Route
                  path={namedRoutes.application.builds}
                  element={<ApplicationBuilds />}
                />
                <Route
                  path={namedRoutes.application.access}
                  element={<ApplicationAccess />}
                />
                <Route
                  path={namedRoutes.application.integrations}
                  element={<ApplicationIntegrations />}
                />
                <Route
                  path={namedRoutes.application.categorization}
                  element={<ApplicationCategorization />}
                />
                <Route
                  path={namedRoutes.application.cloudRendering}
                  element={<ApplicationCloudRenderingSettings />}
                />
              </Route>
            </Route>
            <Route
              path={namedRoutes.user.list}
              handle={{
                crumb: () => (
                  <BreadcrumbNavLink to={namedRoutes.user.list}>
                    Users
                  </BreadcrumbNavLink>
                ),
                title: "Users",
              }}
              element={
                <RequireAuthz
                  requiredPermissions={[
                    "core.change_user",
                    "core.change_usergroup",
                  ]}
                >
                  <UserManagementPage />
                </RequireAuthz>
              }
            >
              <Route index element={<UserList />} />
              <Route
                path={namedRoutes.userGroup.list}
                handle={{
                  crumb: () => (
                    <BreadcrumbNavLink to={namedRoutes.userGroup.list}>
                      User Groups
                    </BreadcrumbNavLink>
                  ),
                  title: "User Groups",
                }}
              >
                <Route
                  index
                  element={<UserGroupList />}
                  errorElement={<RouterErrorElement />}
                />
                <Route
                  path={namedRoutes.userGroup.overview}
                  element={<UserGroupDetails />}
                  handle={{
                    crumb: (match: RouteMatch) => {
                      return (
                        <BreadcrumbNavLink to={match.pathname}>
                          {match.params.groupId && (
                            <UserGroupName id={Number(match.params.groupId)} />
                          )}
                        </BreadcrumbNavLink>
                      );
                    },
                  }}
                  errorElement={<RouterErrorElement />}
                >
                  <Route index element={<UserGroupInfo />} />
                  <Route
                    path={namedRoutes.userGroup.access}
                    element={<UserGroupAccess />}
                  />
                  <Route
                    path={namedRoutes.userGroup.systemAccess}
                    element={<UserGroupSystemAccess />}
                  />
                </Route>
              </Route>
              <Route
                path={namedRoutes.user.overview}
                element={<UserDetails />}
                handle={{
                  crumb: (match: RouteMatch) => {
                    return (
                      <BreadcrumbNavLink to={match.pathname}>
                        {match.params.userId && (
                          <UserName id={Number(match.params.userId)} />
                        )}
                      </BreadcrumbNavLink>
                    );
                  },
                }}
                errorElement={<RouterErrorElement />}
              >
                <Route index element={<UserInfo />} />
                <Route
                  path={namedRoutes.user.activity}
                  element={<UserActivity />}
                />
                <Route
                  path={namedRoutes.user.access}
                  element={<UserAccess />}
                />
              </Route>
            </Route>
            <Route path={namedRoutes.organization.overview}>
              <Route
                path="settings/*"
                element={
                  <RequireAuthz
                    requiredPermissions={["core.change_organization"]}
                  >
                    <OrganizationSettings />
                  </RequireAuthz>
                }
                handle={{
                  title: "Organization Settings",
                }}
              />
              {/* Legacy url to catch redirects for control panel 1 */}
              <Route
                index
                element={
                  <Navigate to={namedRoutes.organization.settings} replace />
                }
              />
            </Route>
            <Route
              path={namedRoutes.cloudRenderingSettings}
              errorElement={<RouterErrorElement />}
            >
              <Route
                path="*"
                element={
                  <RequireAuthz
                    requiredPermissions={["core.change_organization"]}
                  >
                    <CloudRendering />
                  </RequireAuthz>
                }
                handle={{
                  title: "Cloud Rendering Settings",
                }}
              />
              <Route index element={<Navigate to="regions" replace />} />
            </Route>
            <Route
              path={namedRoutes.session.list}
              element={
                <RequireAuthz requiredPermissions={["core.view_user"]}>
                  <SessionsList />
                </RequireAuthz>
              }
              errorElement={<RouterErrorElement />}
              handle={{ title: "Sessions" }}
            />
          </Route>
          <Route path={namedRoutes.accessDenied} element={<AccessDenied />} />
        </Route>
      </Route>
      <Route
        path="*"
        element={
          <QueryClientProvider client={queryClient}>
            <ChakraProvider>
              <CatchAllRoute />
            </ChakraProvider>
          </QueryClientProvider>
        }
      />
    </Route>,
  ),
  { basename: routerBasename },
);

function App() {
  return (
    <FeatureFlagsProvider>
      <RouterProvider
        router={router}
        fallbackElement={<CircularProgress isIndeterminate />}
      />
    </FeatureFlagsProvider>
  );
}

export default App;
