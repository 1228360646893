import { useEffect } from "react";
import { ApplicationBuildId } from "../backend/types";
import {
  useActiveOrganizationQuery,
  usePaginationQueryParams,
  useSessionsQuery,
} from "../hooks";
import { SessionFilters } from "../session-management";
import { SessionsListContext } from "./SessionsListContext";

export function SessionListContextProvider({
  children,
  applicationBuildId,
  from,
  to,
  ...filters
}: {
  children: React.ReactNode;
  applicationBuildId?: ApplicationBuildId;
} & Omit<SessionFilters, "page" | "pageSize" | "appId">) {
  const activeOrganizationQuery = useActiveOrganizationQuery();
  const [pagination, setPagination] = usePaginationQueryParams();
  const query = useSessionsQuery({
    organizationId: activeOrganizationQuery?.data?.id ?? 0,
    includeFailed: true,
    appId: applicationBuildId,
    from,
    to,
    ...filters,
    page: pagination.pageIndex + 1,
    pageSize: pagination.pageSize,
  });

  useEffect(() => {
    // reset pagination if date range (or any other filter changes)
    setPagination((pagination) => ({
      ...pagination,
      pageIndex: 0,
    }));
  }, [from, to, setPagination]);

  return (
    <SessionsListContext.Provider
      value={{ query, setPagination, pagination, filters }}
    >
      {children}
    </SessionsListContext.Provider>
  );
}
