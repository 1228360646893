import { createContext, Dispatch, SetStateAction } from "react";

export type FeatureFlags = {
  granularSessionManagementPermissionsManagement: boolean;
  [key: string]: boolean;
};

export const FeatureFlagsContext = createContext<{
  features: Partial<FeatureFlags>;
  setFeatures: Dispatch<SetStateAction<Partial<FeatureFlags>>>;
}>({ features: {}, setFeatures: () => {} });
